import React from 'react';
import ReactDOM from "react-dom/client";
import { 
    createBrowserRouter,
    RouterProvider,  
} from "react-router-dom";
import './index.scss';

import * as serviceWorker from './serviceWorker';

import Home from './routes/Home';
import Contact from './routes/Contact';
import Reel from './routes/Reel';
import NotFound from './routes/NotFound';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
        errorElement: <NotFound />
    },
    {
        path: "/contact",
        element: <Contact />,
    },
    {
        path: "/reel",
        element: <Reel />,
    }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
    
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
