import React, { Component } from 'react';
import { 
    Box,
    Button, 
    Grid } from '@mui/material';
import { Player, ControlBar } from 'video-react';
import './Home.scss';
import mologo from '../assets/MO-logo_white.svg';
import { withRouter } from '../state/router.js';

class Home extends Component {

    constructor(
        props) {

            super(props);
            this.moviePlayer = React.createRef();

            this.movieClick = this.movieClick.bind(this);
            this.contactClick = this.contactClick.bind(this);
            this.reelClick = this.reelClick.bind(this);
    }

    contactme() {

            window.location.href = 'mailto:mkobrien@gmail.com';
    }

    scrollToTop() {

            window.scrollTo(0, 0);
    }

    reelClick() {

        const { navigate } = this.props;
        navigate('/reel');
    }

    resumeClick() {

            window.location.href = '/documents/MichaelOBrien_Resume_Jan2024.pdf';
    }

    contactClick() {

            const { navigate } = this.props;
            navigate('/contact');
    }

    movieClick() {

            console.log('... click');
            this.moviePlayer.current.startTime = 0;
            this.moviePlayer.current.play();
    }

    render() {

            return (
                <section>
                    <div className="headerparent">
                        <div className="header">
                            
                            <Player 
                                className="headermovie" 
                                onClick={ this.movieClick } 
                                autoPlay 
                                muted
                                inputRef={ this.moviePlayer }
                                src="/movies/b410_207f_1080.mov"
                                poster="/images/TAKES_b410_207f.take_41.148.png"
                                preload="auto"
                                playsInline
                            >
                                <ControlBar disableCompletely />
                            </Player>

                            <Box component="div" className="pageheader">
                                <Grid 
                                    container 
                                    direction="row" 
                                    spacing={1} 
                                    justify="center" 
                                >
                                    <Grid container item xs={4} justify="flex-start">
                                        <Box 
                                            component="div" 
                                            className="headercontact" 
                                            display={{ xs: 'none', sm: 'block' }}
                                        >
                                            510-676-9164 / <a href="mailto:michael@mkobrienfx.com" className="whitelink">michael@mkobrienfx.com</a> 
                                        </Box>
                                    </Grid>
                                    <Grid container item xs={4} justify="center" className="headercenter">
                                        <img src={mologo} className="headerimg" alt="MICHAEL O'BRIEN" />
                                    </Grid>
                                    <Grid container item xs={4} justify="flex-end">
                                        <Box 
                                            component="div" 
                                            className="headerbuttons"
                                            display={{ xs: 'none', sm: 'block' }}
                                        >
                                            <Button onClick={ this.reelClick } classes={{ label: 'headerbuttonlabel' }} className="headerbutton">Reel</Button>
                                            <Button onClick={ this.resumeClick } classes={{ label: 'headerbuttonlabel' }} className="headerbutton">Resume</Button>
                                            <Button onClick={ this.contactClick } classes={{ label: 'headerbuttonlabel' }} className="headerbutton">Contact</Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    </div>
                    
                    <Box
                        component="div" 
                        className="headermobile"
                        display={{ xs: 'block', sm: 'none' }}
                    >
                        <Grid container direction="row" justify="center">
                            <Grid item xs={2} />
                            <Grid item xs={8}>
                                <Button onClick={ this.reelClick } classes={{ label: 'headerbuttonlabel' }} className="headerbutton">Reel</Button>
                                <Button onClick={ this.resumeClick } classes={{ label: 'headerbuttonlabel' }} className="headerbutton">Resume</Button>
                                <Button onClick={ this.contactClick } classes={{ label: 'headerbuttonlabel' }} className="headerbutton">Contact</Button>
                            </Grid>
                            <Grid item xs={2} />
                        </Grid>
                    </Box>

                    <Grid container direction="row" justify="center">
                        <div className="sectionheader">
                            ABOUT ME
                        </div>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={1} sm={3}></Grid>
                        <Grid item xs={10} sm={6}>
                            <Box 
                                component="div"
                                className="intro"
                            >
                                <img 
                                    className="headshotimg" 
                                    src="/images/20210202_mobrien.jpg" 
                                    alt="Headshot" 
                                />
                                <Box
                                    component="div" 
                                    className="copy"
                                >
                                Hola&nbsp;Internet! Welcome to my site.

                                <p className="copy">When I was sixteen, I saved up all my money and bought a computer from a hobby store, so I could teach myself how to make digital pictures. Looking back at my fashion sense and social sensibilities, someone should have recommended I buy a car instead, but I was instantly hooked on making pixels with computer programs. </p>

                                <p className="copy">I really enjoy wrangling math to make art and connecting artists with math all for the purpose of making movies. With my passion and some luck, I put myself through college working at sgi and Alias|Wavefront making tools for artists. With even more luck and the La Nina winds, I joined Pixar in 2000. Starting with <i>Monsters Inc</i> all the way through <i>Elemental</i> in 2022, I was there making movies, building tools, and leading groups of both artistic and technical people. It was a fantastic adventure. I have seen my work in a movie theater, visited art schools all over the country to talk about Pixar, and even figured out the answers to some hard graphics problems. After Pixar, I joined Technicolor Creative Studios as the Senior Vice President of Research and Development heading up a team of 200+ developers world-wide. </p>

                                <p className="copy">Outside of work, I enjoy collaborating with customers that need help with hard visual problems. I worked with Home Planet to create a real-time solar system model based on Nasa ephemeral data. With PopSocket, we created a GPU image manipulation tool that can do advanced image processing and simulation. Loop is another passion project where we created a social framework to make collaborative videos. I really enjoy working with creative people who have a passion for how the world experiences art and communication.</p>

                                <p className="copy">I am constantly looking for new things to learn and new ways to apply technology to art. Lately, I have been focused on improving communication and collaboration using cloud technologies. If you are passionate about art, technology and trying to figure it out together, give me a shout.</p>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={1} sm={3}></Grid>
                    </Grid>
                    
                    <Grid container direction="row" justify="center">
                        <div className="sectionheader">
                            PROFICIENCIES
                        </div>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={1} sm={2} />
                        <Grid item xs={5} sm={4} className="sectionleft">
                            <div className="sectionsubheading">ARTISTIC</div>
                            <div className="skill">Houdini</div>
                            <div className="skill">Katana</div>
                            <div className="skill">Maya</div>
                            <div className="skill">Nuke</div>
                            <div className="skill">Touch Designer</div>
                            <div className="skill">RenderMan, RIS, OSL</div>
                            <div className="skill">PhotoShop, Illustrator, AfterEffects</div>
                        </Grid>
                        <Grid item xs={6} sm={4} className="sectionright">
                            <div className="sectionsubheading">TECHNICAL</div>
                            <div className="skill">C++</div>
                            <div className="skill">OpenGL, HDK, OpenMaya, OpenCL, TBB</div>
                            <div className="skill">Python, Hython, HScript, Flask</div>
                            <div className="skill">JavaScript, Node, React, Vue, D3</div>
                            <div className="skill">Swift, Metal</div>
                            <div className="skill">Oracle, ElasticSearch, Kafka, OpenShift, AWS</div>
                            <div className="skill">Chrome Extensions, Slack Apps, Trello Power-Ups</div>
                        </Grid>
                        <Grid item xs={1} sm={2} />
                    </Grid>
                    
                    <Grid container direction="row" justify="center">
                        <div className="sectionheader">
                            ROLES + ACHIEVEMENTS
                        </div>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={1} sm={2} />
                        <Grid item xs={5} sm={4} className="sectionleft">

                            <div className="projectrole">Senior Vice President of Research and Development</div>
                            <div className="project">Technicolor Creative Studios / 2022-present</div>

                            <div className="projectrole">Production Supervisor</div>
                            <div className="project">SparkShorts</div>

                            <div className="projectrole">Producer</div>
                            <div className="project">Automaton</div>

                            <div className="projectrole">Effects Supervisor</div>
                            <div className="project">Coco</div>
                            
                            <div className="projectrole">Effects Lead</div>
                            <div className="project">The Blue Umbrella</div>
                            
                            <div className="projectrole">Effects Technical Lead</div>
                            <div className="project">The Good Dinosaur, Brave, Lava</div>
                            
                            <div className="projectrole">Effects Artist</div>
                            <div className="project">Onward, Incredibles 2, Finding Dory, Cars, Finding Nemo, Tokyo Mater, Harrison Montgomery</div>

                            <div className="projectrole">Developer</div>
                            <div className="development">Pixar Animation Studios / 2000-2022</div>
                            <div className="development">4 by 4 Studio / 2019-present</div>
                            <div className="development">PopSockets / 2017-2022</div>
                            <div className="development">Autodesk (Alias|Wavefront) / 1997-2000</div>
                            <div className="development">sgi / 1994-1997</div>
                        </Grid>
                        <Grid item xs={5} sm={4} className="sectionright">
                            <div className="sectionsubheading">AWARDS</div>
                            <div className="award">2017 Visual Effects Society Award for Outstanding Effects in an Animated Feature</div>
                            <div className="award">2015 Annie Award for Outstanding Individual Achievement for Animated Effects in an Animated Production</div>
                            <div className="award">2012 Visual Effects Society Award for Outstanding Effects and Simulation Animation in an Animated Feature Motion Picture</div>
                            <div className="sectionsubheading">PATENTS</div>
                            <div className="patent">9240073 / “File Format for Representing a Scene”</div>
                            <div className="patent">9367956 / “Windowed Simulation in Fluid Flows”</div>
                            <div className="patent">9177419 / “Advection of UV Texture Maps in Fluid Flows”</div>
                            <div className="sectionsubheading">SPEAKING ENGAEMENTS</div>
                            <div className="engagement">2012 Siggraph: "River Running through It"</div>
                            <div className="engagement">2013 Siggraph: "It's Raining Monsters"</div>
                            <div className="engagement">2021 Siggraph: "Cartoons in the Cloud"</div>
                        </Grid>
                        <Grid item xs={1} sm={2} />
                    </Grid>

                    <Grid container direction="row" justify="center">
                        <div className="sectionheader">
                            PROJECTS + PORTFOLIO
                        </div>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={1} sm={2} />
                        <Grid item xs={5} sm={4} className="sectionleft">

                            <div className="projecttitle"><i>Technicolor Creative Studios</i>, SVP of R&amp;D</div>
                            <div className="projectdescription">Led a team of 200+ developers located around the world. Set technological direction, assisted in all details of development, handled the budget and goals for the entire department. Supported the pipeline and software stack for the largest VFX studio (MPC), largest animation studio (Mikros), an advertising division (Mill), and TCS Games. Worked with all aspects of the business and external vendors to ensure continuous advancement of TCS's pipeline and creative stack.</div>

                            <div className="projecttitle"><i>SparkShorts</i>, Production Supervisor</div>
                            <div className="projectdescription">SparkShorts allow directors to create a short file with a unique style and tone. As the production supervisor, I setup the film's production, mentored the director and producer on their new roles, and assisted in all stages of the pipeline (from story to final delivery). Of the two shorts I supervised, one was 2D animation and required the creation of a cloud-based pipeline to support working from home.</div>

                            <div className="projecttitle"><i>Coco</i>, Effects Supervisor</div>
                            <div className="projectdescription">Supervised and managed a team of nine artists. Bid the film, cast all work, assisted in execution and delivery of 628 shots with a 290 man-week budget. Created an online forum for sharing and commenting on media, automating several workflows, and providing tools to interact using Slack.</div>

                            <div className="projecttitle"><i>The Good Dinosaur</i>, Effects Technical Lead</div>
                            <div className="projectdescription">Worked with systems, networking, and rendering to create a scalable data pipeline that could service the effects team. Handled all shots where Arlo goes underwater in the river, led the lake swimming sequence, and co-wrote the water shader. Traveled after production doing interviews and presentations to art schools and media outlets. Won an Annie Award for the work.</div>

                            <div className="projecttitle"><i>The Blue Umbrella</i>, Effects Lead</div>
                            <div className="projectdescription">Led a team of five artists on a short film. Bid and cast all effects. Developed and delivered 12 shots. Created tools to manage and run a dailies meeting that gathered media and allowed for gesture-based actions for fast media switching.</div>

                        </Grid>
                        <Grid item xs={5} sm={4} className="sectionright">

                            <div className="projecttitle"><i>Brave</i>, Effects Technical Lead</div>
                            <div className="projectdescription">Led a team of two engineers to design a system to do large scale fluid simulations. Worked with engineers on two ideas about high-resolution fluid simulation that were awarded patents. Led a team of four artists to deliver all river shots on Brave. Won a VES Award for the work.</div>

                            <div className="projecttitle">PopSockets, Developer</div>
                            <div className="projectdescription">Developed an application framework for processing images on an iPhone’s GPU including multi-pass algorithms. Implemented interactive features to allow color correction, sticker placement, and image simulation. Integrated Fritz Machine Learning segmentation to allow users to apply effects to just foreground or background elements.</div>

                            <div className="projecttitle">Loop, Creator and Developer</div>
                            <div className="projectdescription">Developed a collaborative video creation and sharing platform. Implemented backend on AWS to do user authentication, video manipulation, and track analytics. Implemented iPhone app to record or import video, create requests to collaborate on video, and serve those videos to multiple platforms.</div>

                            <div className="projecttitle">TidScene, Developer and Team Lead</div>
                            <div className="projectdescription">Designed and led a three-person engineering team to implement an animated, layered caching format to support scene description as the backplane of the pipeline. Was awarded a patent for the work..</div>

                            <div className="projecttitle">Houdini, Developer</div>
                            <div className="projectdescription">Integrated Houdini into the FX Pipeline. Implemented pipeline interfaces for importing and exporting data. Created a workflow allowing artists to utilize the farm for simulation, processing, and rendering. Interfaced with SideFX to steer future development, custom engineering projects, and continued support.</div>

                            <div className="projecttitle">Home Planet, Developer and Artist</div>
                            <div className="projectdescription">Created visualizations of solar system, local group, and universe scaled phenomenon based on ephemeral data in Touch Designer.</div>
                        </Grid>
                        <Grid item xs={1} sm={2} />
                    </Grid>

                    <Grid container direction="row" align="center" className="footer">
                        <Grid item xs={12} className="footeritem">
                            Please visit my <a href="/reel" className="footerlink">film reel</a> and <a href="http://www.imdb.com/name/nm0639724/?ref_=fn_al_nm_7" className="footerlink">imdb</a> page for more samples of my work. Come back often!
                        </Grid>
                        <Grid item xs={12} className="footeritem">
                            <Button 
                                onClick={ this.scrollToTop } 
                                classes={{ label: "footerbuttonlabel" }}
                                className="footerbutton"
                            >Back to top</Button>
                        </Grid>
                        <Grid item xs={12} className="footeritem" />
                        <Grid item xs={12} className="footeritem">
                            © Michael K. O'Brien, 2020
                        </Grid>
                    </Grid>
                </section>
            )
    }
}

export default withRouter(Home);
