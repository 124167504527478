import React, { Component } from 'react';
import { 
    Grid } from '@mui/material';
import { withRouter } from '../state/router.js';
// import { history } from '../state/history';
// import { AWS } from '../state/aws';
import Header from '../components/Header';
import './NotFound.scss';

class NotFound extends Component {
    
    render() {

        return (
            <section>
                <div className="contactheader">
                    <Header onpage="Contact" />
                </div>

                <Grid container direction="row" justify="center">
                    <div className="sectionheader">
                        NOT FOUND
                    </div>
                </Grid>

                <Grid>
                    Hrm. This seems like you found a dark corner in the site. Please return <a href="/">home</a>.
                </Grid>

            </section>
        )
    }
}

export default withRouter(NotFound);