import React, { Component } from 'react';

import { 
    Grid } from '@mui/material';
import { withRouter } from '../state/router.js';
import Header from '../components/Header';
import { Player, ControlBar } from 'video-react';
import './Reel.scss';

class Reel extends Component {

    render () {

            return (
                <section className="reelpage">

                    <div className="reelheader">
                        <Header onpage="Contact" />
                    </div>

                    <div className="reelspacer"/>
                    <Grid container direction="row" justify="center">
                        <Player 
                            className="reelmovie" 
                            inputRef={ this.moviePlayer }
                            src="/movies/mo_reel_2024.mp4"
                            preload="auto"
                            playsInline
                        >
                            <ControlBar />
                        </Player>
                    </Grid>

                </section>
            );
    }
}

export default withRouter(Reel);