import React, { Component } from 'react'
import { Grid, Box, Button } from '@mui/material';
import mologo from '../assets/MO-logo_white.svg';
import './Header.scss';

class Header extends Component {

    resumeClick() {

        window.location.href = '/documents/MichaelOBrien_Resume_Jan2020.pdf';
    }

    render() {

            return (
                <section>
                    <Grid 
                        container 
                        direction="row" 
                        spacing={1} 
                        className="pageheader"
                        justify="center" 
                    >
                        <Grid container item xs={4} >
                            <Box component="div" className="headercontact" display={{ xs: 'none', sm: 'block' }}>
                                510-676-9164 / <a href="mailto:michael@mkobrienfx.com" className="whitelink">michael@mkobrienfx.com</a> 
                            </Box>
                        </Grid>
                        <Grid container item xs={4} justify="center" className="headercenter">
                            <a href="/"><img src={mologo} className="headerimg" alt="MICHAEL O'BRIEN" /></a>
                        </Grid>
                        <Grid container item xs={4} className="headerright">
                            <Box component="div" display={{ xs: 'none', sm: 'block' }}>
                            <Button onClick={ this.resumeClick } className={"headerbutton"}>Resume</Button>
                            <Button 
                                id="contactbutton" 
                                className="headerbutton"
                            >
                                    <a href="/" className="whitelink">Home</a>
                                </Button>
                            <div className="headerbuttonspace"></div>
                            </Box>
                        </Grid>
                    </Grid>
                </section>
            )
    }
}
  
export default Header;