import React, { Component } from 'react';

import { 
    Button, 
    Grid,
    Snackbar,
    SnackbarContent,
    TextField } from '@mui/material';
import { withRouter } from '../state/router.js';
import { AWS } from '../state/aws';
import Header from '../components/Header';
import './Contact.scss';

class Contact extends Component {

    state = {

        showSnackbar: false
    }

    constructor(
        props) {

            super(props);

            this.nameTextfield = React.createRef();
            this.phoneTextfield = React.createRef();
            this.emailTextfield = React.createRef();
            this.commentTextfield = React.createRef();

            this.senditClick = this.senditClick.bind(this);

            this.state = { showSnackbar: false };

            this.closeSnackbar = this.closeSnackbar.bind(this);
    }

    closeSnackbar(
        anEvent) {

            console.log('... close snackbar');
            this.setState({ showSnackbar: false });
            const { navigate } = this.props;
            navigate(-1);

    }

    senditClick() {

            // Initialize the Amazon Cognito credentials provider
            AWS.config.region = 'us-west-2'; // Region
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({

                IdentityPoolId: 'us-west-2:41ab1a8a-5fe6-40bc-8bdb-cca2350f3fbf',
            });

            const aFeedback = {

                name: this.nameTextfield.current.value,
                phone: this.phoneTextfield.current.value,
                email: this.emailTextfield.current.value,
                comments: this.commentTextfield.current.value
            };

            const aMessage = {

                Message: JSON.stringify(aFeedback),
                TopicArn: 'arn:aws:sns:us-west-2:163960493187:mkobrien_feedback'
            };

            var aSNS = new AWS.SNS({ apiVersion: '2010-03-31' }).publish(aMessage).promise();
            aSNS.then((
                aResult) => {

                    console.log('... feedback published');
            }).catch((
                anError) => { 
                    
                    console.log('!!! unable to publish message: ');
                    console.log(anError);
            });

            console.log('... sending it.')
            console.log('...     ' + JSON.stringify(this.refs));
            console.log('...     name: ' + JSON.stringify(this.nameTextfield.current.value));
            console.log('...     phone: ' + JSON.stringify(this.phoneTextfield.current.value));
            console.log('...     email: ' + JSON.stringify(this.emailTextfield.current.value));
            console.log('...     comments: ' + JSON.stringify(this.commentTextfield.current.value));

            this.nameTextfield.current.value = "";
            this.phoneTextfield.current.value = "";
            this.emailTextfield.current.value = "";
            this.commentTextfield.current.value = "";

            // setTimeout(() => history.goBack(), 1000);
            this.setState({ showSnackbar: true });
    }

    render () {

            return (
                <section className="contactpage">

                    <div className="contactheader">
                        <Header onpage="Contact" />
                    </div>

                    <Grid container direction="row" justify="center">
                        <div className="sectionheader">
                            CONTACT
                        </div>
                    </Grid>

                    <Grid container direction="row" className="contactcontent">
                        <Grid item xs={2} />
                        <Grid item xs={8}>
                            <Grid container direction="row">

                                <Grid item xs={1} md={2}/>
                                <Grid item xs={10} md={8}>
                                    <p className="copy">I am always interested in talking to people who are exploring the 
                                    intersection of art, technology, and communication.</p>
                                    <p className="copy preamble">Feel free to call me at 510-676-9164 or fill out the form below.
                                    I'll get back to you as soon as I can. Thanks for reaching out!</p>
                                </Grid>
                                <Grid item xs={1} md={2}/>

                                <Grid item xs={1} md={2}/>
                                <Grid item xs={10} md={8}>
                                <form className="contactform" noValidate autoComplete="off">
                                    <Grid item xs={12}>
                                        <TextField 
                                            id="name" 
                                            label="Your Name" 
                                            inputRef={ this.nameTextfield }
                                            classes={{ root: 'contacttextfield' }}                                
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField 
                                            id="phone" 
                                            label="Phone" 
                                            inputRef={ this.phoneTextfield }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField 
                                            id="email" 
                                            label="Email" 
                                            inputRef={ this.emailTextfield }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField 
                                            id="comments" 
                                            label="Comments" 
                                            inputRef={ this.commentTextfield }
                                        />
                                    </Grid>
                                </form>
                                </Grid>

                                <Grid container item xs={12} justify="flex-end" alignItems="flex-end">
                                    <Grid item xs={1} md={2}/>
                                    <Grid item xs={10} md={8}>
                                        <Grid container justify="flex-end">
                                            <Button 
                                                id="senditbutton" 
                                                onClick={ this.senditClick }
                                                classes={{ label: 'senditlabel' }}
                                            >
                                            Send It
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} md={2}/>
                                </Grid>
                                <Snackbar
                                    open={ this.state.showSnackbar }
                                    autoHideDuration={ 2000 }
                                    onClose={ this.closeSnackbar }
                                >
                                        <SnackbarContent
                                            message={ 'Message sent!' }
                                        />
                                </Snackbar>
                            </Grid>
                            <Grid item xs={2}/>
                        </Grid>
                        <Grid item xs={2} />
                    </Grid>
                </section>
            );
    }
}

export default withRouter(Contact);